import React, {FunctionComponent, createContext, useContext} from 'react'
import {locale, onMissingKey, onMissingVariable, set, unset} from 'frenchkiss'

import {Language} from '../localization'

onMissingKey(key => {
	console.error(`FrenchKiss: missing key "${key}" in ${locale()} lang.`)
	return `[missing:${key}]`
})

onMissingVariable((variable, key, lang) => {
	console.error(`FrenchKiss: missing variable "${variable}" in ${lang} -> ${key}.`)
	return `[missing:${variable}]`
})

const context = createContext<Language>(undefined)

export const useLanguage = () => useContext(context)

interface Props {
	language: Language
}

export const Localize: FunctionComponent<Props> = ({language, ...props}) => {
	if (locale() !== language.KEY) {
		const key = locale()
		set(language.KEY, language.MESSAGES)
		locale(language.KEY)
		unset(key)
	}
	return <context.Provider value={language} {...props} />
}

// TODO: Consider setting default language as fallBack
// fallback('en');
