{
	/* TODO: Add Header response: noindex, nofollow, canonical, alternate & remove it here  */
	/* TODO: JSON-LD | https://developers.google.com/search/reference/overview */
}

import {Language, useLanguage} from './localization'
import React, {FunctionComponent} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {has, isEmpty} from 'lodash'

import {Helmet} from 'react-helmet'

interface Props {
	title?: string
	language?: Language
	noindex?: boolean
	nofollow?: boolean
	description?: string
	path: string
	author?: string
	cardType?: 'summary' | 'summary_large_image' | 'app' | 'player'
	twitter?: string
	alternate?: [
		{
			key: string
			url: string
		}
	]
	ogType?:
		| 'website'
		| 'music.song'
		| 'music.album'
		| 'music.playlist'
		| 'music.radio_station'
		| 'video.movie'
		| 'video.episode'
		| 'video.tv_show'
		| 'video.other'
		| 'article'
		| 'book'
		| 'profile'
	ogTitle?: string
	ogDescription?: string
	ogImage?: [
		{
			url: string
			alt: string
			width?: number
			height?: number
			type?: string
		}
	]
	ogVideo?: [
		{
			url: string
			alt: string
			width?: number
			height?: number
			type?: string
		}
	]
	ogAudio?: [
		{
			url: string
			type?: string
		}
	]
	object?: object[]
}

export const SEO: FunctionComponent<Props> = ({
	title,
	language = useLanguage(),
	noindex = false,
	nofollow = false,
	description,
	path,
	author,
	cardType = 'summary',
	twitter,
	alternate,
	ogType = 'website',
	ogTitle,
	ogDescription,
	ogImage,
	ogVideo,
	ogAudio,
	object
}) => {
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			site {
				siteMetadata {
					siteUrl
					author
					twitter {
						site
						creator
					}
					facebook {
						id
					}
				}
			}
		}
	`)
	return (
		<Helmet defaultTitle={language.TITLE} titleTemplate={title ? `%s | ${language.NICKNAME}` : `%s`}>
			<html lang={language.KEY} />
			{title && <title>{title}</title>}
			<link rel='canonical' href={data.site.siteMetadata.siteUrl + path} />
			{alternate && alternate.map(link => <link rel='alternate' hrefLang={link.key} href={link.url} />)}
			<meta property='og:locale' content={language.KEY} />
			<meta name='application-name' content={language.TITLE} />
			<meta property='og:site_name' content={language.TITLE} />
			<meta name='description' content={description || language.DESCRIPTION} />
			<meta property='og:description' content={ogDescription || description || language.DESCRIPTION} />
			<meta name='author' content={author || data.site.siteMetadata.author} />
			<meta name='twitter:card' content={cardType} />
			<meta property='og:type' content={ogType} />
			<meta property='og:url' content={data.site.siteMetadata.siteUrl + path} />
			<meta property='og:title' content={ogTitle || title || language.TITLE} />
			{noindex && <meta name='robots' content='noindex,follow' />}
			{nofollow && <meta name='robots' content='index,nofollow' />}
			{!isEmpty(data.site.siteMetadata.twitter.site) && (
				<meta name='twitter:site' content={data.site.siteMetadata.twitter.site} />
			)}
			{twitter ? (
				<meta name='twitter:creator' content={twitter} />
			) : (
				!isEmpty(data.site.siteMetadata.twitter.creator) && (
					<meta name='twitter:creator' content={data.site.siteMetadata.twitter.creator} />
				)
			)}
			{!isEmpty(data.site.siteMetadata.facebook.id) && <meta name='fb:app_id' content={data.site.siteMetadata.facebook.id} />}
			{ogImage &&
				ogImage.map(image => (
					<meta property='og:image' content={data.site.siteMetadata.siteUrl + image.url} />
					// <> FIXME: BUG not rendering
					// 	<meta property='og:image' content={image.url} />
					// 	<meta property='og:image:secure_url' content={image.url} />
					// 	<meta property='og:image:alt' content={image.alt} />
					// 	{has(image, 'width') && <meta property='og:image:width' content={image.width.toString()} />}
					// 	{has(image, 'height') && <meta property='og:image:height' content={image.height.toString()} />}
					// 	{has(image, 'type') && <meta property='og:image:type' content={image.type} />}
					// </>
				))}
			{ogVideo &&
				ogVideo.map(video => (
					<>
						<meta property='og:video' content={video.url} />
						<meta property='og:video:secure_url' content={video.url} />
						<meta property='og:video:alt' content={video.alt} />
						{has(video, 'width') && <meta property='og:video:width' content={video.width.toString()} />}
						{has(video, 'height') && <meta property='og:video:height' content={video.height.toString()} />}
						{has(video, 'type') && <meta property='og:video:type' content={video.type} />}
					</>
				))}
			{ogAudio &&
				ogAudio.map(audio => (
					<>
						<meta property='og:audio' content={audio.url} />
						<meta property='og:audio:secure_url' content={audio.url} />
						{has(audio, 'type') && <meta property='og:audio:type' content={audio.type} />}
					</>
				))}
			{object && object.map(element => <script type='application/ld+json'>{JSON.stringify(element)}</script>)}
		</Helmet>
	)
}
