import {AnimatePresence, motion} from 'framer-motion'

import Layout from '../layout'
import {Localize} from '../localization'
import React from 'react'

export default ({element, props}) => (
	<AnimatePresence exitBeforeEnter>
		<motion.div
			key={props.pageContext.language.KEY}
			initial={{
				opacity: 0
			}}
			animate={{
				opacity: 1
			}}
			exit={{
				opacity: 0
			}}>
			<Localize language={props.pageContext.language}>
				<Layout {...props}>{element}</Layout>
			</Localize>
		</motion.div>
	</AnimatePresence>
)
