import {GatsbyLinkProps, Link} from 'gatsby'
import {Language, localizedPath, useLanguage} from '../localization'
import React, {FunctionComponent} from 'react'

interface Props extends GatsbyLinkProps<{}> {
	language?: Language
}

export const LocalizedLink: FunctionComponent<Props> = ({to, language = useLanguage(), ...props}) => (
	<Link to={localizedPath(language, to)} hrefLang={language.KEY} {...props} />
)
