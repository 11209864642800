import {AnimatePresence, motion} from 'framer-motion'
import {graphql, useStaticQuery} from 'gatsby'

import Background from 'gatsby-background-image'
import React from 'react'
import {SEO} from '../components/seo'

export default ({location: {pathname}, pageContext: {language}, ...props}) => {
	const data = useStaticQuery(graphql`
		query {
			image: file(relativePath: {eq: "background.png"}) {
				relativePath
				childImageSharp {
					fluid(quality: 100) {
						...GatsbyImageSharpFluid
						...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
			}
			seo: file(relativePath: {eq: "ogimage.jpeg"}) {
				relativePath
				childImageSharp {
					fluid(quality: 100, maxWidth: 1200) {
						...GatsbyImageSharpFluid
						...GatsbyImageSharpFluidLimitPresentationSize
					}
				}
			}
		}
	`)
	return (
		<Background
			Tag='div'
			fluid={data.image.childImageSharp.fluid}
			backgroundColor={`#f5f6f7`}
			style={{
				backgroundAttachment: 'fixed',
				backgroundSize: 'auto 100%',
				backgroundPosition: 'center left'

				// display: 'flex',
				// flexDirection: 'column',
				// height: '100vh',
				// width: '100vw',
				// backgroundPosition: 'top left',
				// backgroundRepeat: 'no-repeat',
				// backgroundSize: 'contain',
				// overflow: 'hidden'
			}}>
			{/* <Header /> */}
			<SEO
				cardType='summary_large_image'
				path={pathname}
				ogImage={[
					{
						url: data.seo.childImageSharp.fluid.src,
						alt: 'OSTEO'
					}
				]}
			/>
			<AnimatePresence exitBeforeEnter>
				<motion.main
					key={pathname}
					initial={{
						opacity: 0,
						flex: '1 1 auto'
					}}
					animate={{
						opacity: 1
					}}
					exit={{
						opacity: 0
					}}
					{...props}
				/>
			</AnimatePresence>
		</Background>
	)
}
