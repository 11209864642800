module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.4.14_gatsby@2.23.11/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tom Pichaud","lang":"fr_FR","short_name":"iOsteo","start_url":"/","background_color":"#f5f6f7","APP_color":"#0296ff","display":"minimal-ui","icon":"src/images/icon.png","legacy":false,"cache_busting_mode":"none","localize":[{"start_url":"/en","lang":"en_GB","name":"Tom Pichaud","short_name":"iOsteo","description":"Osteopath DO, awarded by the European School of Osteopathy. Specializations: headaches, athletes and sports injuries, pediatrics, maternity and women's health."},{"start_url":"/fr","lang":"fr_FR","name":"Tom Pichaud","short_name":"iOsteo","description":"Ostéopathe DO, diplômé de l’European School of Osteopathy. Spécialisations: migraines, athlètes et pathologies du sport, pédiatrie, maternité et santé de la femme."}],"include_favicon":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@3.2.13_gatsby@2.23.11/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@2.3.6_gatsby@2.23.11/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#0296ff","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@2.5.6_1e241a27e7cd0b1ce0ba71ab1e67f5b7/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-preact@4.0.5_2b2df173f8d588272df5bd60d29f003f/node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
